import { createSlice } from '@reduxjs/toolkit';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { createParkingArea, deleteParkingArea, getAllParkingAreas, updateParkingArea, uploadImage } from './parkingAreaAPI';

export const parkingAreaSlice = createSlice({
  name: 'parkingArea',
  initialState: {
    parkingAreas: [],
    searchValue: '',
    searchField: 'address',
    pagination: {},
    page: 0,
    rowsPerPage: 10,
    sort: 'desc',
    sortBy: 'createdAt',
    selectedId: null,
    selectedArea: null,
    addModalOpen: false,
    editModalOpen: false,
    deleteModalOpen: false,
    successMessage: "",
    errorMessage: ""
  },
  reducers: {
    setSelectedId: (state, action) => {
      state.selectedId = action.payload;
    },
    setSelectedArea: (state, action) => {
      state.selectedArea = action.payload;
    },
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setSearchField: (state, action) => {
      state.searchField = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setPagination: (state, action) => {
      state.pagination = action.payload;
    },
    setSort: (state, action) => {
      state.sort = action.payload;
    },
    setSortBy: (state, action) => {
      state.sortBy = action.payload;
    },
    setParkingAreas: (state, action) => {
      state.parkingAreas = action.payload;
    },
    showAddModal: (state, action) => {
      state.addModalOpen = action.payload;
    },
    showEditModal: (state, action) => {
      state.editModalOpen = action.payload;
    },
    showDeleteModal: (state, action) => {
      state.deleteModalOpen = action.payload;
    },
    setSuccessMessage: (state, action) => {
      state.successMessage = action.payload;
    },
    setErrorMessage: (state, action) => {
      state.errorMessage = action.payload;
    }
  }
});

export const { setSelectedId, setSelectedArea, setSearchValue, setSearchField, setPage, setRowsPerPage, setPagination, setSort, setSortBy, setParkingAreas, showAddModal, showEditModal, showDeleteModal, setSuccessMessage, setErrorMessage } = parkingAreaSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.vendor.value)`
export const selectState = state => state.parkingArea;

export const handleGetParkingAreas = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    const response = await getAllParkingAreas(state.parkingArea.page, state.parkingArea.rowsPerPage, state.parkingArea.sort, state.parkingArea.sortBy, state.parkingArea.searchField, state.parkingArea.searchValue);
    const { content, ...pagination } = response.data;

    dispatch(setPagination(pagination));
    dispatch(setParkingAreas(content));
  } catch (error) {
    console.log(error.message);
  } finally {
    dispatch(hideLoading());
  }
};

export const handleAddParkingArea = (parkingArea, addImage, imageData) => async (dispatch, getState) => {
  dispatch(showLoading());

  try {
    const response = await createParkingArea(parkingArea);
    addImage && dispatch(handleUploadPhoto(response.data.id, imageData));
    dispatch(showAddModal(false));
    dispatch(setSuccessMessage("Successfully added parking area!"));
    dispatch(handleGetParkingAreas());
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUpdateParkingArea = (parkingArea, addImage, imageData) => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await updateParkingArea(state.parkingArea.selectedId, parkingArea);
    addImage && dispatch(handleUploadPhoto(state.parkingArea.selectedId, imageData));
    dispatch(showEditModal(false));
    dispatch(setSuccessMessage("Successfully updated parking area!"));
    dispatch(handleGetParkingAreas());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleDeleteParkingArea = () => async (dispatch, getState) => {
  dispatch(showLoading());
  const state = getState();

  try {
    await deleteParkingArea(state.parkingArea.selectedId);
    dispatch(showDeleteModal(false));
    dispatch(setSuccessMessage("Successfully deleted parking area!"));
    dispatch(handleGetParkingAreas());
    dispatch(setSelectedId(null));
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export const handleUploadPhoto = (id, imageData) => async (dispatch, getState) => {
  dispatch(showLoading());

  try {
    await uploadImage(id, imageData);
    dispatch(setSuccessMessage("Successfully uploaded parking area image!"));
    dispatch(handleGetParkingAreas());
  } catch (error) {
    dispatch(setErrorMessage(error.message));
  } finally {
    dispatch(hideLoading());
  }
};

export default parkingAreaSlice.reducer;